import React, { useState } from "react";

const ItemContext = React.createContext({});

const ItemContextProvider = ({ values, children }) => {
  const [selectedModifiers, setSelectedModifiers] = useState([]);
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [disableAddToCart, setDisableAddToCart] = useState(false);
  const [totalModifiersRequired, setTotalModifiersRequired] = useState(0);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <ItemContext.Provider
      value={{
        selectedModifiers,
        setSelectedModifiers,
        specialInstructions,
        setSpecialInstructions,
        showModal,
        setShowModal,
        handleShow,
        handleClose,
        disableAddToCart,
        setDisableAddToCart,
        totalModifiersRequired,
        setTotalModifiersRequired,
        ...values,
      }}
    >
      {children}
    </ItemContext.Provider>
  );
};
export default ItemContext;
export { ItemContextProvider, ItemContext };
