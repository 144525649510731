import axios from "axios";

const reverseGeocode = async (pos) => {
  try {
    return await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.lat},${pos.lng}&key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}`,
    );
  } catch (error) {
    console.log({ error });
    return error;
  }
};

export { reverseGeocode };
