import React, { useContext } from "react";
import { Row, Col, Container } from "react-bootstrap";

import { CartContext, LocationsContext } from "../../context";
import StrapiImage, { STRAPI_IMAGE_SIZES } from "../../components/StrapiImage";
import { usePriceFormat, useTrans } from "../../hooks";
import _ from "lodash";

const RestaurantBanner = ({ restaurant }) => {
  const locationsContext = useContext(LocationsContext);
  const cartContext = useContext(CartContext);
  const { priceFormat } = usePriceFormat();
  const transMsg = useTrans();

  // ================================================================
  return (
    <>
      <section className="restaurant-detailed-banner">
        <div id="logo" className="text-center ">
          <center>
            <StrapiImage
              className="cover"
              alt="banner"
              width="100%"
              src={locationsContext?.selectedLocation?.photo?.url || ""}
            />
          </center>
        </div>
        <div className="restaurant-detailed-header pl-md-4 pb-md-4">
          <Container>
            <Row className="d-flex align-items-end">
              <Col md={12}>
                <div className="restaurant-detailed-header-left">
                  <StrapiImage
                    src={restaurant?.logo?.url || ""}
                    className="mr-3 float-left roundImg"
                    alt="logo"
                    size={STRAPI_IMAGE_SIZES.THUMBNAIL}
                  />
                  <h2 className="text-white capitalize" style={{ fontSize: "28px" }}>
                    {locationsContext.selectedLocation?.name}
                  </h2>

                  {cartContext.orderType === "delivery" &&
                    !_.isUndefined(cartContext.cartTotals?.deliveryFees) && (
                      <>
                        {cartContext.canDeliver ? (
                          <>
                            <div className="text-white capitalize mb-2 mb-md-0">
                              {transMsg("delivery")}{" "}
                              {priceFormat(cartContext.cartTotals?.deliveryFees)}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-danger capitalize h4 mb-2">
                              {transMsg("cannotDeliverMessage")}{" "}
                            </div>
                          </>
                        )}
                      </>
                    )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default RestaurantBanner;
