import React, { useEffect, useContext, useState } from "react";
import { Container } from "react-bootstrap";

import Layout from "../../components/Layout";
import OrderTypeModal from "../../components/OrderType/modal";
import { LocationsContext, CartContext, GoogleMapsContext } from "../../context";
import _ from "lodash";
// import auth from "../../utils/auth";
import { reverseGeocode } from "../../utils/reverseGeocode";
import OrderOnline from "./orderOnline";
import RestaurantBanner from "./banner";
import RestaurantDetials from "./restaurantDetials";
import { useSessionStorage } from "../../hooks";
import OrderType from "../../components/OrderType";
import SelectShareLocation from "../../components/SelectShareLocation";

import { LocationType } from "../../context/locationsContext";

const Detail = ({ pageContext }) => {
  const {
    locations,
    menu,
    seoData,
    layoutProps: { hasMultipleShareLocations, restaurant, ...layoutPropsRest },
  }: { locations: LocationType[]; menu: any; seoData: any; layoutProps: any } = pageContext;

  const locationsContext = useContext(LocationsContext);
  const { isLoaded } = useContext(GoogleMapsContext);

  const cartContext = useContext(CartContext);
  const [seen, setSeen] = useSessionStorage("SelectShareLocationHasBeenSeen", false);

  const [proximityLocationGiven, setProximityLocationGiven] = useState(false);
  const [pos, setPos] = useState<{ lat: number; lng: number }>(
    {} as { lat: number; lng: number },
  );
  const [postalCode, setPostalCode] = useState("");

  const selectRandomLocation = () => {
    const res = _.sample(locations);
    if (!res) {
      console.log("No locations found to select a random location");
      return;
    }
    locationsContext.setSelectedLocation(res);
  };

  const initializeLocationsContext = () => {
    // log with a message
    console.log("Details -> initializeLocationsContext -> setting locations to context");
    // set the locations to the context
    locationsContext.setLocations(locations);
  };

  const getUserGeolocation = (): Promise<{ lat: number; lng: number }> => {
    // create a promise that resolves to the userposition
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          // If the user denies location, run this code
          reject();
        },
        // options sent to the browser geolocation API to control the accuracy of the location data
        {
          enableHighAccuracy: true,
        },
      );
    });
  };

  // create an effect that will run only once to initialize the locations context
  useEffect(() => {
    if (_.isEmpty(locations)) return;

    // choose a random location for the user
    if (_.isEmpty(locationsContext.selectedLocation)) {
      console.log("Selecting Random Location");
      selectRandomLocation();
    }

    // initialize the locations context
    if (_.isEmpty(locationsContext.locations)) {
      console.log("Initialize Locations Context");
      initializeLocationsContext();
    }
  }, []);

  useEffect(() => {
    const updateSelectedLocationBusinessHours = async () => {
      if (!locationsContext.selectedLocation.id) return;

      const { storefrontLocation } = await locationsContext.fetchLocationBusinessHours(
        locationsContext.selectedLocation.id,
      );
      locationsContext.setSelectedLocationBusinessHours(storefrontLocation);
    };

    updateSelectedLocationBusinessHours();
  }, [locationsContext.selectedLocation?.id]);

  // create an effect that will run only once to get the user's location
  useEffect(() => {
    (async () => {
      try {
        const pos = await getUserGeolocation();
        setPos(pos);
      } catch (err) {
        console.log("User denied location");
      }
    })();
  }, []);

  const getPostalCodeFromGeolocation = async (pos: { lat: number; lng: number }) => {
    const res = await reverseGeocode(pos);
    return res.data.results
      .find((result) => result.types[0] === "postal_code")
      ?.address_components.find((component) => component.types[0] === "postal_code")
      ?.long_name.substring(0, 3);
  };

  // create an effect that will run only when the user's geolocation is changed
  // it will get the postal code of the user and set it to the postalCode state
  useEffect(() => {
    if (_.isEmpty(pos)) return;

    (async () => {
      try {
        const postalCode = await getPostalCodeFromGeolocation(pos);
        setPostalCode(postalCode);
      } catch (err) {
        console.log("Error getting postal code from geolocation");
      }
    })();
  }, [pos]);

  // create an effect that will run only when the user's geolocation is changed
  // it will get the distance between the user and the restaurant locations
  // and set the closest location to the selected location in the locations context
  useEffect(() => {
    if (_.isEmpty(pos) || !isLoaded) return;
    if (seen) return;

    (async () => {
      try {
        // log with a message
        console.log("Details -> useEffect -> getting distance matrix");
        const distanceMatrixResponse = await locationsContext.getDistanceMatrixToLocations(
          pos,
          locations,
        );
        const closestLocation = locationsContext.getClosestLocation(
          distanceMatrixResponse,
          locations,
        );

        // update the context and state
        locationsContext.setSelectedLocation(closestLocation);
        setSeen(true);
      } catch (err) {
        console.log("Error getting distance matrix");
      }
    })();
  }, [pos, isLoaded]);

  // ================================================================
  return (
    <>
      {seen || !hasMultipleShareLocations ? (
        <>
          <Layout
            seo={seoData}
            showCart
            showBottomCart
            showOrderType
            hasMultipleShareLocations={hasMultipleShareLocations}
            restaurant={restaurant}
            {...layoutPropsRest}
          >
            <RestaurantBanner restaurant={restaurant} />
            <OrderTypeModal />

            <Container>
              <center>
                <div className="row mt-3">
                  <div className="col-12">
                    <OrderType showModal />
                  </div>
                </div>
              </center>
              <RestaurantDetials />
              <hr />
              <OrderOnline
                menu={menu}
                logo={restaurant?.logo?.url}
                locationId={locationsContext.selectedLocation?.id}
              />
            </Container>
          </Layout>
        </>
      ) : (
        <>
          <SelectShareLocation
            restaurant={restaurant}
            seoData={seoData}
            onSelect={() => {
              setSeen(true);
            }}
          />
        </>
      )}
    </>
  );
};

export default Detail;
