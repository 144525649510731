import React, { useEffect, useState } from "react";
import fetchPromotions from "../queries/fetchPromotions";
import usePriceFormat from "../hooks/usePriceFormat";
import "bootstrap/dist/css/bootstrap.min.css";

interface DeliveryDiscount {
  minOrderPrice: number;
  discount: number;
}

interface PromotionalFeaturesProps {
  logo?: string;
  locationId: string;
}

const PromotionalFeatures: React.FC<PromotionalFeaturesProps> = ({ logo, locationId }) => {
  const [promotions, setPromotions] = useState<DeliveryDiscount[]>([]);
  const { priceFormat } = usePriceFormat();

  useEffect(() => {
    async function loadPromotions() {
      if (!locationId) {
        console.error("No locationId provided to PromotionalFeatures");
        return;
      }
      const fetchedPromotions = await fetchPromotions(locationId);
      setPromotions(fetchedPromotions);
    }

    loadPromotions();
  }, [locationId]);

  return (
    <div className="container-fluid px-3 my-3">
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
        {promotions.map((promo, index) => (
          <div key={index} className="col">
            <div className="card h-100 border-dark" style={{ backgroundColor: "#f8f9fa" }}>
              <div className="card-body p-3 d-flex justify-content-between align-items-center">
                <div className="flex-grow-1">
                  <h5 className="card-title mb-1">
                    <span
                      className="font-weight-bold"
                      style={{ fontSize: "1.2rem", color: "#dc3545" }}
                    >
                      {priceFormat(promo.discount)} OFF
                    </span>{" "}
                    <span style={{ fontSize: "1rem" }}>Delivery fee</span>
                  </h5>
                  {promo.minOrderPrice > 0 && (
                    <p className="mb-1 text-muted" style={{ fontSize: "0.9rem" }}>
                      Minimum order {priceFormat(promo.minOrderPrice)}
                    </p>
                  )}
                  <span
                    className="badge bg-success text-white rounded-pill"
                    style={{ fontSize: "0.75rem" }}
                  >
                    Automatically applied
                  </span>
                </div>
                {logo && (
                  <img
                    src={logo}
                    alt="Restaurant logo"
                    className="rounded-circle ml-3"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                      border: "2px solid #343a40",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PromotionalFeatures;
