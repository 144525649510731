import React, { useContext, useEffect, useState } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import Icofont from "../../components/IcoFont";
import { useFlexSearch } from "react-use-flexsearch";
import { SearchContext } from "../../context";
import { useTrans } from "../../hooks";

const SearchBar = () => {
  const transMsg = useTrans();
  const [query, setQuery] = useState(null);
  const { setSearchResult } = useContext(SearchContext);
  const {
    localSearchItems: { index, store },
  } = useStaticQuery(graphql`
    query {
      localSearchItems {
        index

        store
      }
    }
  `);

  const results = useFlexSearch(query, index, store, { suggest: true });

  useEffect(() => {
    setSearchResult(results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);
  // ================================================================
  return (
    <>
      <div className="explore-outlets-search search-width">
        <InputGroup>
          <Form.Control
            className="search-input mb-2"
            type="text"
            placeholder={transMsg("searchMenu") + "..."}
            value={query || ""}
            onChange={({ target: { value } }) => {
              setQuery(value);
            }}
          />
          <InputGroup.Append>
            <Button type="button" variant="link" aria-label="Search">
              <Icofont icon="search" />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </div>
    </>
  );
};

export default SearchBar;
