import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import _ from "lodash";
import produce from "immer";
import { ItemContext } from "../itemContext";
import { useLocalization } from "../../../hooks";
import { useTrans } from "../../../hooks";

const Modifiers = () => {
  const transMsg = useTrans();
  const { locale: currentLocale } = useLocalization();

  const [selected, setSelected] = useState({});

  const {
    setSelectedModifiers,
    modifiers,
    priceUnit,
    setDisableAddToCart,
    totalModifiersRequired,
    setTotalModifiersRequired,
  } = useContext(ItemContext);
  // BEGIN MODAL

  useEffect(() => {
    if (_.isEmpty(selected)) setSelectedModifiers([]);
    else setSelectedModifiers(_.flatten(_.values(selected).map((v) => _.values(v))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (_.isEmpty(modifiers)) return;
    const _required = _.filter(modifiers, (v) => v[currentLocale].isRequire);
    setTotalModifiersRequired(_required.length);
    if (_required.length > 0) setDisableAddToCart(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiers]);

  const onOptionSelected = (
    { modifier, item, itemModifier },
    name,
    type,
    checked,
    maxOptions,
    isRequire,
  ) => {
    if (type === "radio")
      setSelected({
        ...selected,
        [name]: { [item.id]: { modifier, item, quantity: 1, isRequire } },
      });

    if (type === "checkbox") {
      setSelected(
        produce(selected, (draft) => {
          if (!draft[name]) draft[name] = {};

          if (checked && _.keys(draft[name]).length < maxOptions)
            draft[name][item.id] = { modifier, item, quantity: 1, isRequire };
          else delete draft[name][item.id];
        }),
      );

      if (itemModifier && itemModifier.length > 0) {
        // this is a sub modifier of a modifier
        // check if it's required, if it is, then increment the total required by maxOptions
        _.map(itemModifier, (modifier) => {
          if (modifier[currentLocale].isRequire) {
            setTotalModifiersRequired(
              totalModifiersRequired + modifier[currentLocale].maxOptions,
            );
          }
        });
      }
    }
  };
  //===========================================================

  // create a function that will loop the modifiers and create a form group for each modifier
  const renderModifier = (modifier, isSubModifier, parentItemModifier) => {
    const {
      name: modifierName,
      listType: modifierListType,
      maxOptions: modifierMaxOptions,
      isRequire: modifierIsRequire,
      id: modifierId,
    } = modifier[currentLocale];

    return (
      <React.Fragment key={modifier.id}>
        <Form.Group controlId="formBasicCheckbox">
          {
            // add a div that has py-3 to add some space between the modifiers and a pl-2 to add some space to the left
            // give it a background color of #f6f6f6 to make it look like a header
            // add a Form.Label that will show the name of the modifier and if it is required, then show a red asterisk
            // make it look like a header using the className of h5
            // if the modifier is a sub item modifier, then make the header look like a sub item modifier and add border line
          }
          <div
            // make it smaller if it is a sub modifier and add a border line to the top
            className={isSubModifier ? "py-1 pl-2 border-top" : "py-3 pl-2"}
            style={{ backgroundColor: "#f6f6f6" }}
          >
            <Form.Label
              column={isSubModifier ? "sm" : "lg"}
              style={
                // remove the default padding from the Form.Label and margin
                {
                  padding: 0,
                  margin: 0,
                }
              }
            >
              {modifierName}{" "}
              {modifierIsRequire ? (
                // show a red asterisk if the modifier is required
                <span className="text-danger">*</span>
              ) : (
                ""
              )}
              {
                //  check what kind of list type it is
                // if it is a checkbox, then show the max options using transMsg("selectUpTo")
                // make it on a new line mutted out
                modifierListType === "checkbox" ? (
                  <p className="item-sub m-0">
                    {transMsg("selectUpTo")} {modifierMaxOptions}
                  </p>
                ) : (
                  ""
                )
              }
            </Form.Label>
          </div>
          <div className="mb-2">
            {_.map(modifier.items, ({ item }) => {
              const { name, price, id } = item[currentLocale];
              // check if the item has modifiers and if it does
              // then rerender the modifiers
              const itemModifier = item.modifiers;

              return (
                <>
                  {
                    // Make a div that will wrap the Form.Check
                    // give it a className of row to make it look like a row
                    // give it a className of py-2 to add some space between the items
                    // create a div that will show the name of the item
                    // give it a className of col-9 to make it look like a column
                    // create a div that will show the price of the item
                    // give it a className of col-3 to make it look like a column
                    // add a Form.Check for each item with a ml-3 to add some space to the left
                    // give it a type of radio or checkbox depending on the modifier list type
                    // give it a name of the modifier id
                    // give it an id of the item id
                    // give it a label of the name
                    // check if the item is selected
                    // if it is selected, then check it
                    // if it is required, then add a required attribute
                    // add an onChange function that will call the onOptionSelected function
                    // pass the modifier, item, itemModifier, name, type, checked, maxOptions, isRequire
                  }
                  <div className="row py-2">
                    <div className="col-9">
                      <Form.Check
                        className="ml-3"
                        type={modifierListType}
                        name={
                          isSubModifier
                            ? `${parentItemModifier.id}-${modifierId}`
                            : modifierId
                        }
                        id={
                          isSubModifier
                            ? `${parentItemModifier.id}-${modifierId}-${id}`
                            : id
                        }
                        label={name}
                        checked={
                          // check if the item is selected
                          (selected[
                            isSubModifier
                              ? `${parentItemModifier.id}-${modifierId}`
                              : modifierId
                          ] &&
                            selected[
                              isSubModifier
                                ? `${parentItemModifier.id}-${modifierId}`
                                : modifierId
                            ][id]) ||
                          false
                        }
                        required={modifierIsRequire}
                        onChange={({ target: { name, type, checked } }) => {
                          onOptionSelected(
                            {
                              modifier: {
                                name: modifierName,
                                id: modifierId,
                              },
                              item: item[currentLocale],
                              itemModifier,
                            },
                            name,
                            type,
                            checked,
                            modifierMaxOptions,
                            modifierIsRequire,
                          );
                        }}
                      />
                    </div>
                    <div className="col-3">
                      {
                        // check if the price is greater than 0
                        // if it is greater than 0, then show the price fixed to 2 decimal places
                        // if it is not greater than 0, then show nothing

                        price > 0 ? (
                          <span className="text-muted">
                            +{priceUnit}
                            {price.toFixed(2)}
                          </span>
                        ) : (
                          ""
                        )
                      }
                    </div>
                  </div>

                  {
                    // check if the itemModifier has modifiers
                    // if it has a modifier, then loop the modifiers and render the modifiers
                    // make it hidden by default until the item is selected and the setShowSubItemModifier is true
                    // if it doesn't have a modifier, then show nothing
                    // add a className of ml-4 to add some space to the left

                    itemModifier && itemModifier.length > 0 && (
                      <div
                        className="ml-4"
                        style={{
                          display:
                            selected[
                              isSubModifier
                                ? `${parentItemModifier.id}-${modifierId}`
                                : modifierId
                            ] &&
                            selected[
                              isSubModifier
                                ? `${parentItemModifier.id}-${modifierId}`
                                : modifierId
                            ][id]
                              ? "block"
                              : "none",
                        }}
                      >
                        {_.map(itemModifier, (modifier) => {
                          return <>{renderModifier(modifier, true, item[currentLocale])}</>;
                        })}
                      </div>
                    )
                  }
                </>
              );
            })}
          </div>
        </Form.Group>
      </React.Fragment>
    );
  };

  return (
    <>
      {!_.isEmpty(modifiers) && (
        <>{_.map(modifiers, (modifier) => renderModifier(modifier, false))}</>
      )}
    </>
  );
};

export default Modifiers;
