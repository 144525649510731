import { gql } from "@apollo/client";
import client from "../utils/apolloClient";
import { LANGUAGES_SUPPORT_MAP } from "../../createPages/utils";

// Define GraphQL fragments for reusability and readability
const CATEGORY_FRAGMENT = gql`
  fragment categoryFragment on Category {
    id
    description
    position
    name
    photo {
      url
      alternativeText
    }
    locale
    localeShareId
    availability
    items(
      where: {
        sellByItsOwn: true
        locale: $defaultLanguage
        availability_ne: "soldout_indefinitely"
      }
    ) {
      ...itemFragment
      localizations(where: { locale: $languages }) {
        ...itemFragment
      }
      modifiers(
        where: { locale: $defaultLanguage, availability_ne: "soldout_indefinitely" }
      ) {
        id
      }
    }
  }
`;

const ITEM_FRAGMENT = gql`
  fragment itemFragment on Items {
    id
    description
    handle
    name
    price
    photo {
      url
      alternativeText
    }
    locale
    localeShareId
    availability
  }
`;

// Function to fetch menu from the server
const fetchMenu = async (id) => {
  const QUERY = gql`
    ${CATEGORY_FRAGMENT}
    ${ITEM_FRAGMENT}

    query GetMenu($id: ID!, $defaultLanguage: String!, $languages: [String]) {
      menu(id: $id) {
        categories(
          sort: "position:asc"
          where: { locale: $defaultLanguage, availability_ne: "soldout_indefinitely" }
        ) {
          ...categoryFragment
          localizations(where: { locale: $languages }) {
            ...categoryFragment
          }
        }
      }
    }
  `;

  return client.query({
    query: QUERY,
    variables: { id, defaultLanguage: "en", languages: Object.keys(LANGUAGES_SUPPORT_MAP) },
  });
};

export default fetchMenu;
