import React from "react";
import ItemCard from "./card";
import ItemModal from "./Modal";

import { ItemContextProvider } from "./itemContext";

const ItemContainer = ({ ...props }) => {
  //===========================================================
  return (
    <>
      <ItemContextProvider
        values={{
          ...props,
        }}
      >
        <ItemCard />
        <ItemModal />
      </ItemContextProvider>
    </>
  );
};

ItemContainer.defaultProps = {
  itemClass: "gold-members",
  imageAlt: "",
  imageClass: "",
  showBadge: false,
  subTitle: "",
  price: "",
  priceUnit: "$",
  showPromoted: false,
  badgeVariant: "warning",
  quantity: 0,
  show: true,
  max: 99,
  min: 0,
};

export default ItemContainer;
