import React, { useContext } from "react";
import { Badge, Media } from "react-bootstrap";
import StrapiImage, { STRAPI_IMAGE_SIZES } from "../StrapiImage";

import { ItemContext } from "./itemContext";
import { usePriceFormat } from "../../hooks";
import SoldOut from "./soldOut";
const ItemCard = () => {
  const {
    handleShow,
    quantity,
    itemClass,
    subTitle,
    image,
    imageAlt,
    imageClass,
    price,
    badgeText,
    badgeVariant,
    title,
    availability,
    showBadge,
  } = useContext(ItemContext);
  // BEGIN MODAL

  const { priceFormat } = usePriceFormat();
  //===========================================================
  return (
    <>
      <div
        // TODO: conditional border-when-item
        className={
          quantity > 0
            ? "border-when-item menu-item p-3 quick-bite border-bottom " + itemClass
            : "menu-item p-3 quick-bite border-bottom " + itemClass
        }
        onKeyPress={() => {}}
        role="button"
        tabIndex={0}
        onClick={handleShow}
      >
        <SoldOut availability={availability} right />
        <Media className={subTitle ? "item-w-desc" : ""}>
          {quantity > 0 ? (
            <div className="qty-menu-item">
              <p>{quantity}</p>
            </div>
          ) : null}
          {image ? (
            <div className="trimMeSmall mr-3">
              <StrapiImage
                className={"mr-3 rounded-pillOFF " + imageClass}
                src={image}
                alt={imageAlt}
                size={STRAPI_IMAGE_SIZES.THUMBNAIL}
                style={{
                  height: 130,
                  width: 130,
                  objectFit: "cover",
                }}
              />
            </div>
          ) : null}
          <Media.Body>
            <div className="mb-1 capitalize menu-item-title">
              {title}

              {/* <span style={{ color: "grey", fontSize: "13px" }}>
                {priceUnit}
                {price}
              </span> */}
              {showBadge ? <Badge variant={badgeVariant}>{badgeText}</Badge> : ""}
              {/* <p className="text-gray mb-3 menu-desc quick-bite-priceOF">
                {priceUnit}
                {price.toFixed(2)}
              </p> */}
            </div>
            {subTitle ? (
              <span className="ellipsis">
                <p className="text-grayOFF menu-desc" style={{ color: "#767676" }}>
                  {subTitle}
                </p>
              </span>
            ) : (
              ""
            )}
            <span className="item-price mt-1">{priceFormat(price)}</span>
          </Media.Body>
        </Media>
      </div>
      <br />
    </>
  );
};

export default ItemCard;
