const _ = require("lodash");
const { LANGUAGES_SUPPORT_MAP } = require("../utils");

/**
 * Example
 *
 * categories = {items:[],en:{},fr:{}}
 */
const mapCategories = (categories) => {
  const catKeys = [
    "id",
    "description",
    "position",
    "name",
    "photo",
    "locale",
    "availability",
  ];
  return categories.map((cat) => {
    return {
      ...mapData(cat, catKeys),
      items: mapItems(cat.items),
    };
  });
};

/**
 * Example
 *
 * items = {modifiers:[],en:{},fr:{}}
 */
const mapItems = (items) => {
  const itemKeys = [
    "id",
    "description",
    "handle",
    "name",
    "photo",
    "price",
    "locale",
    "localeShareId",
    "availability",
  ];
  return items.map((item) => {
    return {
      ...mapData(item, itemKeys),
      modifiers: mapModifiers(item.modifiers),
    };
  });
};

/**
 * Example
 *
 * items = {items:[],en:{},fr:{}}
 */
const mapModifiers = (modifiers) => {
  const modifierKeys = [
    "id",
    "name",
    "listType",
    "maxOptions",
    "isRequire",
    "locale",
    "availability",
  ];

  return modifiers.map((modifier) => {
    let res = mapData(modifier, modifierKeys);

    if (modifier.modifier) {
      res.modifier = mapData(modifier.modifier, modifierKeys);
      res.modifier.items = mapModifierItems(modifier.modifier.items);
    }

    res.items = mapModifierItems(modifier.items);
    return res;
  });
};

const mapModifierItemsModifier = (modifierItems) => {
  const modifierKeys = [
    "id",
    "name",
    "listType",
    "maxOptions",
    "isRequire",
    "locale",
    "availability",
  ];

  return modifierItems.map((modifierItem) => {
    // check if there are items in the modifier
    // if there are items, then we need to map them
    // and return the mapped items

    if (modifierItem.items) {
      return {
        ...mapData(modifierItem, modifierKeys),
        items: mapModifierItems(modifierItem.items),
      };
    }

    // if there are no items, then we just return the modifierItem
    return {
      ...mapData(modifierItem, modifierKeys),
    };
  });
};

const mapModifierItems = (modifierItems) => {
  const modifierItemKeys = ["id", "price", "name", "locale", "availability"];
  return modifierItems.map((modifierItem) => {
    return {
      item: {
        ...mapData(modifierItem.item, modifierItemKeys),
        modifiers: _.isEmpty(modifierItem.item.modifiers)
          ? []
          : mapModifierItemsModifier(modifierItem.item.modifiers),
      },
      maxQuantity: modifierItem.maxQuantity,
    };
  });
};

const mapData = (row, rowsKeys) => {
  let res = {};
  _.values(LANGUAGES_SUPPORT_MAP).map((language) => {
    res[language] = _.pick(row, rowsKeys);
  });
  if (!_.isEmpty(row.localizations)) {
    row.localizations.forEach((rowLocale) => {
      res[LANGUAGES_SUPPORT_MAP[rowLocale.locale]] = {
        ...res[LANGUAGES_SUPPORT_MAP[rowLocale.locale]],
        ..._.pick(rowLocale, rowsKeys),
      };
    });
  }

  return res;
};

module.exports = { mapCategories };
