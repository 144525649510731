// storefront/src/queries/fetchModifiers.js
import { gql } from "@apollo/client";
import client from "../utils/apolloClient";
import { LANGUAGES_SUPPORT_MAP } from "../../createPages/utils";

// Define GraphQL fragments for reusability and readability
const MODIFIER_FRAGMENT = gql`
  fragment ModifierFields on Modifiers {
    id
    name
    listType
    maxOptions
    isRequire
    locale
    availability
  }
`;

const ITEM_FRAGMENT = gql`
  fragment ItemFields on Items {
    id
    description
    handle
    name
    price
    photo {
      url
      alternativeText
    }
    locale
    localeShareId
    availability
  }
`;

// Define GraphQL query to fetch modifiers
const FETCH_MODIFIERS = gql`
  ${MODIFIER_FRAGMENT}
  ${ITEM_FRAGMENT}
  query getModifiers($restaurantId: ID!, $defaultLanguage: String!, $languages: [String]) {
    modifiers(where: { restaurant: $restaurantId, locale: $defaultLanguage }) {
      ...ModifierFields
      localizations(where: { locale: $languages }) {
        ...ModifierFields
      }
      items {
        item {
          ...ItemFields
          localizations(where: { locale: $languages }) {
            ...ItemFields
          }
          modifiers {
            ...ModifierFields
            items {
              item {
                ...ItemFields
              }
            }
          }
        }
        maxQuantity
      }
      modifier {
        ...ModifierFields
        localizations(where: { locale: $languages }) {
          ...ModifierFields
        }
        items {
          item {
            ...ItemFields
            localizations(where: { locale: $languages }) {
              ...ItemFields
            }
          }
          maxQuantity
        }
      }
    }
  }
`;

// Function to fetch modifiers from the server
const fetchModifiers = async () => {
  const response = client.query({
    query: FETCH_MODIFIERS,
    variables: {
      restaurantId: process.env.GATSBY_STRAPI_RESTAURANT_ID,
      defaultLanguage: "en",
      languages: Object.keys(LANGUAGES_SUPPORT_MAP),
    },
  });

  return response;
};

export default fetchModifiers;
