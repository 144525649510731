import { gql } from "@apollo/client";
import client from "../utils/apolloClient";

// Define the DeliveryDiscount interface
interface DeliveryDiscount {
  minOrderPrice: number;
  discount: number;
}

// Define the query
const GET_DELIVERY_DISCOUNTS = gql`
  query GetDeliveryDiscounts($locationId: ID!) {
    getDeliveryDiscountByLocation(locationId: $locationId) {
      minOrderPrice
      discount
    }
  }
`;

// Function to fetch promotions
async function fetchPromotions(locationId: string): Promise<DeliveryDiscount[]> {
  try {
    const { data, errors } = await client.query({
      query: GET_DELIVERY_DISCOUNTS,
      variables: { locationId },
    });

    if (errors) {
      console.error("GraphQL Errors:", errors);
      return [];
    }

    if (!data || !data.getDeliveryDiscountByLocation) {
      console.error("Unexpected response structure:", data);
      return [];
    }

    return data.getDeliveryDiscountByLocation;
  } catch (error) {
    console.error("Error fetching promotions:", error);
    if (error.networkError) {
      console.error("Network error details:", error.networkError);
    }
    return [];
  }
}

export default fetchPromotions;
