const _ = require("lodash");

const LANGUAGES_SUPPORT_MAP = (() => {
  if (_.isEmpty(process.env.GATSBY_LANGUAGES_SUPPORT)) return [];

  const backend_languages_support = ["en", "fr"];
  const languages = _.split(process.env.GATSBY_LANGUAGES_SUPPORT, " ");
  return languages.reduce((r, l, i) => {
    r[backend_languages_support[i]] = l;
    return r;
  }, {});
})();

module.exports = {
  LANGUAGES_SUPPORT_MAP,
};
