import React, { useContext } from "react";
import { BreakpointContext } from "../../context";
import { useTrans } from "../../hooks";

const SoldOut = ({ availability, right }) => {
  const transMsg = useTrans();
  const breakpoint = useContext(BreakpointContext);
  return (
    <div>
      {availability === "soldout_today" && (
        <div
          className="p-2 mr-3 position-absolute "
          style={{
            backgroundColor: "#ff0100",
            color: "white",
            fontSize: breakpoint.md ? 13 : 15,
            right: right ? "0px" : "",
            top: breakpoint.md ? "-20px" : "0px",
          }}
        >
          {transMsg("soldOutToday")}
        </div>
      )}
    </div>
  );
};
export default SoldOut;
