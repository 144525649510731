import React, { useContext } from "react";
import { Badge, Form } from "react-bootstrap";
import StrapiImage, { STRAPI_IMAGE_SIZES } from "../../StrapiImage";
import Modal from "react-bootstrap/Modal";
import ItemModalFooter from "./modalFooter";
import Modifiers from "./modifiers";
import { ItemContext } from "../itemContext";
import { useTrans } from "../../../hooks";
import SoldOut from "../soldOut";

const ItemModal = () => {
  const {
    showModal,
    handleClose,
    setSpecialInstructions,
    subTitle,
    image,
    imageAlt,
    imageClass,
    badgeText,
    badgeVariant,
    title,
    price,
    priceUnit,
    showBadge,
    availability,
  } = useContext(ItemContext);
  const transMsg = useTrans();
  //===========================================================
  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <SoldOut availability={availability} />

        <Modal.Header closeButton className="loc-modal-header"></Modal.Header>
        <Modal.Body className="modal-body">
          <h2 className="mb-3 capitalize menu-item-titlek">
            {title}
            <p className="text-gray mb-0 mt-1 menu-desc">
              {priceUnit}
              {price.toFixed(2)}
            </p>

            {showBadge ? <Badge variant={badgeVariant}>{badgeText}</Badge> : ""}
          </h2>
          {subTitle ? <p className="text-light-grey mb-3 menu-desc">{subTitle}</p> : ""}
          {image ? (
            <center>
              <StrapiImage
                className={"rounded-pillOFF item-img" + imageClass}
                src={image}
                alt={imageAlt}
                size={STRAPI_IMAGE_SIZES.SMALL}
                style={{ width: "100%", height: 325, objectFit: "contain" }}
              />
            </center>
          ) : null}
          <div>
            <Form>
              <br />
              <Modifiers />
              <hr style={{ marginTop: "20px" }} />
              <div>
                <h5> {transMsg("preferences")}</h5>
              </div>
              <Form.Group
                controlId="exampleForm.ControlTextarea1"
                style={{ marginBottom: "0px", marginTop: "15px" }}
              >
                <Form.Control
                  as="textarea"
                  placeholder={transMsg("addANoteOrInstructions") + "..."}
                  rows={4}
                  className="special-ins"
                  onChange={({ target: { value } }) => {
                    setSpecialInstructions(value);
                  }}
                />
              </Form.Group>
            </Form>
            <br />
          </div>
          <ItemModalFooter />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ItemModal;
